import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import {
    OKTA_AUTH,
    OktaAuthModule,
} from '@okta/okta-angular';
import { OktaAuth, Tokens } from '@okta/okta-auth-js';
import { environment } from '../../../environments/environment';
import { LoginResponse } from '../../interfaces/login-response';
import { UserLoginRequest } from '../../interfaces/user-login-request';
import { GlobalService } from '../../services/global/global.service';
import { LoaderService } from '../../services/loader/loader.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { UserService } from '../../services/user/user.service';
import {
    Messages,
    NavigationURLs,
    SKPTConstants,
} from '../../utils/application-constants';
import { DateUtils } from '../../utils/date-utils';
import { OTPVerificationComponent } from '../otp-verification/otp-verification.component';
import { LoaderComponent } from '../shared/loader/loader.component';
import { ToasterComponent } from '../shared/toaster/toaster.component';
@Component({
    selector: 'app-login',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        OktaAuthModule,
        LoaderComponent,
        OTPVerificationComponent,
        ToasterComponent,
        NgbCarouselModule,
    ],
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'], // Updated to use CSS
})
export class LoginComponent implements OnInit {
    emailForm: any;
    passwordForm: any;
    hidePassword: boolean = true;
    isSKPTUser: boolean = false;

    userLoginRequest: UserLoginRequest = {
        email: '',
        password: '',
    };

    errorMessage: string = '';
    isEmailEntered: boolean = false;
    Constants = SKPTConstants;
    Messages = Messages;
    is2FaNeeded: boolean = false;

    loginResponse: LoginResponse = {
        userId: '',
        otpCreatedOn: '',
        otpDigitsLength: 0,
        otpExpiryTime: 0,
        success: false,
        otpMaxTrial: 0,
    };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private userService: UserService,
        private localStorageService: LocalStorageService,
        private loaderService: LoaderService,
        public globalService: GlobalService,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
        // public authService: OktaAuthStateService
    ) { }

    async ngOnInit() {
        this.emailForm = this.fb.group({
            email: [
                // 'platformadmin@mailinator.com',
                '',
                [
                    Validators.required,
                    Validators.email,
                    Validators.pattern(SKPTConstants.PATTERN_REQUIRED_CHARS_IN_USERNAME),
                ],
            ],
            password: [
                // '123Pa$$word!',
                '',
                [Validators.required],
            ],
        });

        this.passwordForm = this.fb.group({
            password: [
                // '123Pa$$word!',
                '',
                [Validators.required],
            ],
        });

        if (localStorage.getItem('initiatedSsoLogin')) {
            this.loaderService.showLoader();
            localStorage.removeItem('initiatedSsoLogin');

            try {
                await this.oktaAuth.handleLoginRedirect();
                await this.checkAuthentication();
            } catch (error) {
                console.error('Error handling login redirect:', error);
                this.loaderService.hideLoader();
            }
        }
    }

    private async checkAuthentication() {
        try {
            const isAuthenticated = await this.oktaAuth.isAuthenticated();

            if (isAuthenticated) {
                await this.retrieveTokens();
            } else {
                this.handleUnauthenticatedState();
            }
        } catch (error) {
            console.error('Error checking authentication status:', error);
            this.loaderService.hideLoader();
        }
    }

    private async retrieveTokens() {
        try {
            const tokens: Tokens = this.oktaAuth.tokenManager.getTokensSync();
            // const accessToken = tokens.accessToken?.accessToken; // Retrieve the ID token
            const accessToken = tokens.idToken?.idToken; // Retrieve the ID token
            const refreshToken = tokens.refreshToken?.refreshToken;

            if (accessToken) {
                const userClaims = await this.oktaAuth.getUser();
                this.setUserAndNavigate(userClaims, accessToken, refreshToken||'');
            } else {
                throw new Error('Error in fetching Access token.');
            }
        } catch (error) {
            console.error('Error retrieving tokens:', error);
            this.loaderService.hideLoader();
        }
    }

    private setUserAndNavigate(userClaims: any, accessToken: string, refreshToken: string) {
        const isSsoLogin = true;
        const isUserLoggedIn = true;
        let roleModuleMapping = [];
        if(localStorage.getItem('initiatedSsoLogin')) {
            roleModuleMapping = this.localStorageService.getRoleModuleMapping();
        }
        

        this.localStorageService.clear();
        this.localStorageService.setCurrentUser(
            {
                refreshToken: refreshToken,
                token: accessToken,
                username: userClaims?.name,
                name: userClaims?.preferred_username,
                email: userClaims?.email,
            },
            isUserLoggedIn,
            isSsoLogin
        );

        if(roleModuleMapping && roleModuleMapping.length && this.localStorageService.getRoleModuleMapping().length <= 0) {
            this.localStorageService.setRoleModuleMapping(roleModuleMapping);
        }
        
        this.router.navigate([NavigationURLs.HOME]);
    }

    private handleUnauthenticatedState() {
        setTimeout(() => {
            this.emailForm.reset();
            this.passwordForm.reset();
            this.loaderService.hideLoader();
            this.localStorageService.clear();
            sessionStorage.clear();
        }, 2000);
    }

    // Custom validator function
    disallowedCharactersValidator(disallowedChars: string[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            } // No value, no error

            const foundChars = disallowedChars.filter((char) =>
                control.value.includes(char)
            );
            if (foundChars.length > 0) {
                return { disallowedCharacters: foundChars };
            }
            return null;
        };
    }

    onSubmit(): void {
        this.errorMessage = '';
        this.loaderService.showLoader();
        this.userService.authenticateUser(this.userLoginRequest).subscribe({
            next: (result: any) => {
                this.localStorageService.clear();
                if (result.success && result.data) {
                    const data = result.data;
                    if (data.token) {
                        this.is2FaNeeded = false;
                        const isSsoLogin = false;
                        const isUserLoggedIn = true;
                        this.localStorageService.setCurrentUser(
                            result?.data,
                            isUserLoggedIn,
                            isSsoLogin
                        );

                        // initialize signalR connection on Home Page after log in
                        this.router.navigate([NavigationURLs.HOME]);
                        // this.loaderService.hideLoader();
                    } else {
                        this.loginResponse.userId = data.userId;
                        this.loginResponse.otpCreatedOn = data.otpCreatedOn;
                        this.loginResponse.otpDigitsLength = data.otpDigitsLength
                            ? data.otpDigitsLength
                            : 0;
                        this.loginResponse.otpExpiryTime = data.otpExpiryTime
                            ? DateUtils.convertTimeToMinutesSafe(data.otpExpiryTime)
                            : 0;
                        this.loginResponse.otpMaxTrial = data.otpMaxTrial;
                        // this.loginResponse.otpExpiryTime = 1;

                        this.is2FaNeeded = true;
                    }
                } else {
                    this.errorMessage = result?.message;
                    this.loaderService.hideLoader();
                }
            },
            error: (error: any) => {
                this.loaderService.hideLoader();

                if (error?.status === 401) {
                    if (error?.error?.message) {
                        if(error?.error?.message === Messages.USER_IS_DEACTIVATED) {
                            this.router.navigate(['unauthorised']);
                        } else {
                            this.errorMessage = error?.error?.message;
                        }
                    } else {
                        this.errorMessage = 'Error logging in, please contact the admin.';
                    }
                } else if (error?.status === 400) {
                    if (error?.error?.errors?.Password[0]) {
                        this.errorMessage = Messages.INVALID_CREDENTIALS;
                    } else if (error?.error?.message) {
                        this.errorMessage = error?.error?.message;
                    } else {
                        this.errorMessage = error.message;
                    }
                } else {
                    this.errorMessage = 'Error in logging in, please contact the admin.';
                    return;
                }
            },
        });
    }

    convertTimeToMinutesSafe(time: string): number {
        const parts = time.split(':').map(Number);
        if (parts.length !== 3 || parts.some(isNaN)) {
            console.error('Invalid time format');
            return 0;
        }
        const [hours, minutes, seconds] = parts;
        return hours * 60 + minutes + seconds / 60;
    }

    navigateToForgotPassword(): void {
        this.router.navigate([NavigationURLs.FORGOT_PASSWORD]); // Navigate to the Reset Password Page
    }

    async onSubmitEmailId(): Promise<void> {
        this.errorMessage = '';
        const _emailId: string = this.emailForm.value.email;
        // if (emailId && this.checkIfEmailHasListedDomain(emailId)) {
        this.loaderService.showLoader();

        try {
            localStorage.setItem('initiatedSsoLogin', 'true');
            // await this.oktaAuth.signInWithRedirect({ prompt: 'login' });
            // await this.oktaAuth.signInWithRedirect({ loginHint: emailId });

            await this.oktaAuth.signInWithRedirect();
            // this.setUserDetails();
        } catch (err: any) {
            console.error(err);
        }
    }

    onSubmitPassword(): void {
        const emailId: string = this.emailForm.value.email;
        const password: string = this.emailForm.value.password;
        if (emailId && password && this.checkIfEmailHasListedDomain()) {
            this.userLoginRequest.email = emailId;
            this.userLoginRequest.password = password;
            this.errorMessage = '';
            this.onSubmit();            
        }
        else {
            this.errorMessage = 'Please login with OKTA';
        }
    }

    checkIfEmailHasListedDomain(): boolean {
        this.isSKPTUser = false;
        const emailId = this.emailForm.value.email;
        if (!emailId || !environment?.ssoEmailDomains) {
            return false;
        }

        const domain = emailId.split('@')[1]?.toLowerCase(); // Extract domain part
        if (!domain) {
            return false;
        }
        if (environment.ssoEmailDomains.some(
            (listedDomain) => domain === listedDomain.toLowerCase()
        )) {
            this.errorMessage = 'Please login with OKTA';
           
            return false;
        } else {
            this.isSKPTUser = true;
            return true;
        }
         
    }

    backToEmail(): void {
        this.loaderService.showLoader();
        this.passwordForm.reset();
        this.isEmailEntered = false;
        setTimeout(() => {
            this.loaderService.hideLoader();
        }, 50);
    }

    handleBackToLogin() {
        this.resetLoginRequestAndResponse();
        this.is2FaNeeded = false;
        this.emailForm.reset();
        localStorage.clear();
    }

    resetLoginRequestAndResponse() {
        this.loginResponse = {
            userId: '',
            otpCreatedOn: '',
            otpDigitsLength: 0,
            otpExpiryTime: 0,
            success: false,
            otpMaxTrial: 0,
        };

        this.userLoginRequest = {
            email: '',
            password: '',
        };
    }

    onPasswordChange() {
        this.errorMessage = '';
    }

    onEmailChange() {
        this.errorMessage = '';
    }
    trimAllFields() {
        // Trim whitespace from all form control values
        Object.keys(this.emailForm.value.email).forEach(key => {
            const control = this.emailForm.get(key);
            if (control && typeof control.value === 'string') {
                control.setValue(control.value.trim());
            }
        });
    }
  
}
